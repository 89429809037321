<template>
  <div>
    <b-modal no-close-on-backdrop @hidden="$emit('hidden')" ref="modal-packings" size="sm" :title="title + ' PACKING'">
      <div class="m-2">
        <validation-observer ref="observer">
          <div class="form-row">
            <div class="form-group col-md-12">
              <validation-provider v-slot="{ errors }" rules="required" name="bankName">
                <b-form-group label="TYPE OF PACKING">
                  <b-form-input autofocus v-model="typeOfPacking" type="text" class="form-control" :class="errors[0]">
                  </b-form-input>
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <b-form-group label="DESCRIPTION">
                <b-form-textarea id="textarea" v-model="description" placeholder="Describe your packing.." rows="3"
                  max-rows="6" type="text" class="form-control">
                </b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </validation-observer>
      </div>
      <template #modal-footer="{ cancel }">
        <div>
          <button-cancel   @click="cancel()">CANCEL</button-cancel>
        </div>
        <div>
          <b-button variant="primary" @click="setAction()"><feather-icon icon="SaveIcon"  />
            {{ textButton }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SettingsService from "@/views/logistic/views/settings/services/settings.service.js";
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ButtonCancel
  },

  props: {
    dataAccount: {
      type: Object,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      description: "",
      typeOfPacking: "",
      id: 0,
      title: "ADD",
      textButton: "SAVE",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-packings");

    if (this.isUpdate) {
      this.showPackings();
      this.title = "UPDATE";
      this.textButton = "UPDATE";
    }
  },

  methods: {
    async createPackings() {
      const result = await this.$refs.observer.validate();

      if (result) {
        try {
          const params = {
            typeOfPacking: this.typeOfPacking,
            description: this.description,
            user_id: this.currentUser.user_id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SettingsService.savePackings(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.cancel();
              this.$emit("onCreatePackings");
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    async updatePacking() {
      const result = await this.$refs.observer.validate();

      if (result) {
        try {
          const params = {
            typeOfPacking: this.typeOfPacking,
            description: this.description,
            user_id: this.currentUser.user_id,
            id: this.id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SettingsService.updatePackings(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.cancel();
              this.$emit("onCreatePackings");
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    showPackings() {
      this.typeOfPacking = this.dataAccount.name;
      this.id = this.dataAccount.id;
      this.description = this.dataAccount.description;
    },
    setAction() {
      if (this.isUpdate) {
        this.updatePacking();
      }
      else {
        this.createPackings();
      }
    },
    cancel() {
      this.$emit("closeModal");
    },
  },
};
</script>
